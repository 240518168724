import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, Heading, FormControl, FormLabel, useToast, Text } from '@chakra-ui/react';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        const isAuthenticated = !!document.cookie.includes('token'); // Verifica si el token está presente
        // console.log("isAuthenticated: ",document.cookie)
        if (isAuthenticated) {
            navigate('/'); // Redirige al usuario a la página principal si ya está autenticado
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include',
            });

            if (response.ok) {
                // console.log("Autentificacion OK")
                navigate('/'); // Redirige al usuario al dashboard u otra página protegida
            } else {
                const errMessage = await response.json();
                setError(errMessage.message);
                toast({
                    title: 'Error',
                    description: errMessage.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setError('Error al conectarse con el servidor');
            toast({
                title: 'Error',
                description: 'Error al conectarse con el servidor',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box maxWidth="400px" mx="auto" mt="100px">
            <Heading mb={6} textAlign="center">Login</Heading>
            <FormControl mb={4}>
                <FormLabel>Username</FormLabel>
                <Input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your username"
                />
            </FormControl>
            <FormControl mb={6}>
                <FormLabel>Password</FormLabel>
                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                />
            </FormControl>
            {error && (
                <Text color="red.500" mb={4}>
                    {error}
                </Text>
            )}
            <Button colorScheme="teal" onClick={handleLogin} width="100%">
                Login
            </Button>
        </Box>
    );
}

export default Login;