import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Proyectos from './components/Proyectos';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
function App() {

  return (
    <Router>
       <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <Proyectos />
                        </PrivateRoute>
                    }
                />
                {/* Otras rutas */}
            </Routes>
    </Router>
  );
}

export default App;