import React from 'react';
import { Box, Button, Container, Flex, Heading, Image, Stack, Text, Link, SimpleGrid } from '@chakra-ui/react';
import Logo from '../../assets/images/logo dark.png'
import imagen1 from '../../assets/images/Prueba de 10 sensores DHT22.png'
import imagen2 from '../../assets/images/Prueba de estabilidad de la Estación MeteoSystem.png'
import imagen3 from '../../assets/images/Prueba de modulo LoRaWAN y Gatweay HELTEC.png'
import imagen4 from '../../assets/images/Prueba de modulo GPRS.png'
import imagen5 from '../../assets/images/Servidor IoT - Telegraf.png'
import imagen6 from '../../assets/images/FRC Fundación Tecnológica Chile.png'



function Proyectos() {

  // Define your projects data
  const projects = [
    {
      id: 1,
      title: "Prueba de 10 sensores DHT22",
      description: "Monitoreo de 10 sensores DHT22 con ESP8266 en plataforma Grafana + Influxdb a través de protocolo MQTT.",
      image: imagen1,
      link: "https://iot.veamchile.cl/grafana/d/oxZ34Vt4k/sensores-iot"
    },
    {
      id: 2,
      title: "Prueba de estabilidad de la Estación MeteoSystem",
      description: "Estación meteorológica básica: Temperatura, Humedad, Velocidad del viento, Dirección del viento, Presión atmosférica, Altitud, Punto de roció, Lluvia caída.",
      image: imagen2,
      link: "https://iot.veamchile.cl/grafana/d/b5681e99-bdc8-4c47-9689-88c4b3fb4c71/meteosystem"
    },
    {
      id: 3,
      title: "Prueba de modulo LoRaWAN y Gateway HELTEC",
      description: "Registro y monitoreo de una variable 'Contador' desde un dispositivo LoRaWAN",
      image: imagen3,
      link: "https://iot.veamchile.cl/grafana/d/c41a86ec-fb92-4e2e-a328-c8887ab399d4/test-lorawan"
    },
    {
      id: 4,
      title: "Prueba de modulo GPRS",
      description: "Registro y monitoreo de una variable 'Contador' desde un dispositivo GPRS",
      image: imagen4,
      link: "https://iot.veamchile.cl/grafana/d/e5cd50ae-4df8-4f53-a58e-3d3ac1a73649/test-gprs"
    },
    {
      id: 5,
      title: "Servidor IoT - Telegraf",
      description: "Registro y monitoreo de métricas del servidor como memoria utilizada, CPU el %, cantidad de procesos, etc.",
      image: imagen5,
      link: "https://iot.veamchile.cl/grafana/d/7JdIfTn9z/servidor-iot-telegraf"
    },
    {
      id: 6,
      title: "FRC Fundación Tecnológica Chile",
      description: "Proyecto Realizado para disminuir la brecha tecnológica en la comunidad.",
      image: imagen6,
      link: "https://fundaciontecnochile.org/"
    }
  ];

  async function handleLogout() {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include', // Asegúrate de incluir cookies en la solicitud
      });

      if (response.redirected) {
        window.location.href = response.url;
      } else {
        // Manejar errores si la redirección no ocurre
        alert('No se pudo cerrar sesión correctamente.');
      }
    } catch (error) {
      console.error('Error al conectarse con el servidor:', error);
      alert('Error al conectarse con el servidor.');
    }
  }

  return (
    <Box>
      <Flex as="nav" p={4} bg="gray.100" align="center" justify="space-between">
        <Flex align="center">
          <Link href="https://iot.veamchile.cl">
            <Image src={Logo} boxSize="36px" alt="VeAm cHiLe IoT" />
          </Link>
          <Heading ml={4}>VeAm cHiLe IoT</Heading>
        </Flex>
        <Flex>
          <Link href="https://www.veamchile.cl" isExternal mx={2}>www.veamchile.cl</Link>
          <Link href="https://iot.veamchile.cl/grafana" isExternal mx={2}>Grafana</Link>
          <Link href="https://iot.veamchile.cl:8086" isExternal mx={2}>InfluxDB</Link>
          <Link href="https://iot.veamchile.cl/emqx" isExternal mx={2}>EMQX</Link>
          <Link href="https://iot.veamchile.cl:8090" isExternal mx={2}>ChirpStack</Link>
          <Link href="https://iot.veamchile.cl:/sicom" isExternal mx={2}>SICOM-PZC</Link>
          <Link href="https://nam1.cloud.thethings.network/console" isExternal mx={2}>The Things Stack</Link>
          <Button onClick={() => handleLogout()} ml={4}>Cerrar sesión</Button>
        </Flex>
      </Flex>
      <Container maxW="container.xl" py={8}>
        <Stack spacing={8}>
          <Box textAlign="center">
            <Heading mb={4}>Proyectos</Heading>
            <Text>Presentamos algunos proyectos que hemos realizado</Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {projects.map(project => (
              <Box key={project.id} borderWidth={1} borderRadius="md" overflow="hidden" maxW="md">
                <Image src={project.image} alt={project.title} height="205px" objectFit="cover" />
                <Box p={4} flex="1">
                  <Heading size="md" mb={2} textAlign="center" height="60px">{project.title}</Heading>
                  <Text mb={4} textAlign="justify" height="100px">{project.description}</Text>
                  <Button colorScheme="teal" as={Link} href={project.link} isExternal width="full">
                    Dashboard
                  </Button>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
}



export default Proyectos;