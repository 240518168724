import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Spinner, Box } from '@chakra-ui/react';

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // const isAuthenticated = !!document.cookie.split(';').find(c => c.trim().startsWith('token='));

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch('/api/checkAuth', {
                    method: 'GET',
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = await response.json();
                    setIsAuthenticated(data.authenticated);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner
                    size="lg"
                    color="teal.500"
                    thickness="4px"
                />
            </Box>
        )
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;